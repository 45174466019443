import { createGlobalState } from 'react-hooks-global-state';

const initialState = {
  userQuizEventPoints: 0,
  numberOfQuizzes: null,
  onVerticallyIndexedPage: false,
  isGenericPage: false,
  lastQuizCompletedTimestamp: 0,
  componentOrder: [],
};

const { setGlobalState, useGlobalState, getGlobalState } = createGlobalState(initialState);

export const setUserQuizEventPoints = (userQuizEventPoints) => {
  setGlobalState('userQuizEventPoints', userQuizEventPoints);
};

export const setNumberOfQuizzes = (numberOfQuizzes) => {
  setGlobalState('numberOfQuizzes', numberOfQuizzes);
};

export const setOnVerticallyIndexedPage = (onVerticallyIndexedPage) => {
  setGlobalState('onVerticallyIndexedPage', onVerticallyIndexedPage);
};

export const setIsGenericPage = (isGenericPage) => {
  setGlobalState('isGenericPage', isGenericPage);
};

export const setLastQuizCompletedTimestamp = (lastQuizCompletedTimestamp) => {
  setGlobalState('lastQuizCompletedTimestamp', lastQuizCompletedTimestamp);
};

export const setComponentOrder = (componentOrder) => {
  setGlobalState('componentOrder', componentOrder);
};

export { useGlobalState, getGlobalState };
