import React from 'react';
import PropTypes from 'prop-types';
import s from './CarouselArrow.module.scss';

const propTypes = {
  type: PropTypes.oneOf(['next', 'prev']),
  onClick: PropTypes.func,
  containerClass: PropTypes.string,
  circleBorder: PropTypes.bool,
  className: PropTypes.string,
};

const CarouselArrow = ({
  type = 'prev',
  onClick = () => {},
  containerClass = '',
  circleBorder = false,
  className = '',
}) => {
  return (
    <div
      className={[className, containerClass, circleBorder ? s.circleBorder : '', s.arrow].join(' ')}
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex="-1"
      role="button"
      data-testid="carousel-arrow"
    >
      {type === 'next' ? <div className={s.next} /> : <div className={s.prev} />}
    </div>
  );
};

CarouselArrow.propTypes = propTypes;
export default CarouselArrow;
