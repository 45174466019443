import { useRef, useState, useEffect } from 'react';
import { useGlobalState as GenericPageGlobalState } from '../../globalStates/GenericPageState';

export const DATA_ROW_CONTENTFUL_ID = 'data-row-contentful-id';

const useVerticalIndex = () => {
  const [componentOrder] = GenericPageGlobalState('componentOrder');
  const [onVerticallyIndexedPage] = GenericPageGlobalState('onVerticallyIndexedPage');
  const componentRef = useRef(null);
  const [verticalIndex, setVerticalIndex] = useState(null);

  useEffect(() => {
    if (componentRef.current && onVerticallyIndexedPage) {
      const rowElement = componentRef.current?.closest(`[${DATA_ROW_CONTENTFUL_ID}]`);
      const rowContentfulId = rowElement?.getAttribute(DATA_ROW_CONTENTFUL_ID);

      // 0 is navbar, so we add 1 to get the correct index
      const workingIndex = rowContentfulId ? componentOrder.indexOf(rowContentfulId) : null;
      setVerticalIndex(workingIndex !== -1 && workingIndex !== null ? workingIndex + 1 : null);
    } else {
      setVerticalIndex(null);
    }
  }, [componentOrder, onVerticallyIndexedPage]);

  return { componentRef, verticalIndex };
};

export default useVerticalIndex;
