import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { REGION } from '../../utils/graphQueries';

const useCountry = () => {
  const [country, setCountry] = useState('');
  const { loading, data } = useQuery(REGION, {
    ssr: false,
  });

  useEffect(() => {
    if (data && data.register) {
      setCountry(data.register.country);
    }
  }, [data]);

  return {
    loading,
    country,
  };
};

export default useCountry;
